<template>
	<div>
		<!-- top -->
		<div class="top">
			<div class="left">
				<div class="select">
					<el-date-picker
						v-model="date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						size="mini">
					</el-date-picker>
				  <el-radio-group v-model="radio4" size="mini">
						<el-radio-button label="今日"></el-radio-button>
						<el-radio-button label="昨天"></el-radio-button>
						<el-radio-button label="本月"></el-radio-button>
						<el-radio-button label="上月"></el-radio-button>
					</el-radio-group>
					<el-select 
					v-model="value" 
					placeholder="选择部门"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select
					v-model="value" 
					placeholder="选择员工"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select
					v-model="value" 
					placeholder="选择员工状态"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-popover
						placement="bottom"
						width="300"
						trigger="click">
						<div class="detaill">
							<div class="top">
								<span>显示</span>
								<span>恢复默认</span>
							</div>
							<div class="center">
								<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
								  <el-checkbox 
									v-for="(city,index) in cities" 
									:key="index"
									:label="city">
									  {{city}}
									</el-checkbox>
								</el-checkbox-group>
							</div>
							<div class="bottom">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
								<el-button type="primary" size="mini">确定</el-button>
							</div>
						</div>
						<div class="look" slot="reference">
							<el-button icon="el-icon-s-grid" size="mini"></el-button>
						</div>
					</el-popover>
				</div>
			</div>
			<div class="right">
				<el-button size="mini" type="primary">保存为常用</el-button>
				<el-button size="mini" type="primary">更多</el-button>
			</div>
		</div><!-- end top -->  
		<!-- table -->
		<el-table
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}"
			show-summary
			size="small">
			<el-table-column
				prop="date"
				label="工号 / 员工"
				width="200"
				fixed
				sortable>
			</el-table-column>
			<el-table-column
				prop="date"
				label="岗位 / 职位"
				width="200">
			</el-table-column>
			<el-table-column label="汇总" align="center">
				<el-table-column
					prop="province"
					label="总业绩"
					width="100">
				</el-table-column>
				<el-table-column
					prop="city"
					label="总提成"
					width="120">
				</el-table-column>
			</el-table-column>
			<el-table-column label="支付类型" align="center">
				<el-table-column
					prop="zip"
					label="现金业绩"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="卡金业绩"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="疗程卡业绩"
					width="120">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="其它支付业绩"
					width="120">
				</el-table-column>
			</el-table-column>
			<el-table-column label="劳动" align="center">
				<el-table-column
					prop="zip"
					label="劳动业绩"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="劳动提成"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column label="商品" align="center">
				<el-table-column
					prop="zip"
					label="商品业绩"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="商品提成"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column label="开充卡" align="center">
				<el-table-column
					prop="zip"
					label="开充卡业绩"
					width="120">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="开充卡提成"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column
				prop="zip"
				label="疗程卡"
				width="120"
				align="center">
				<el-table-column
					prop="zip"
					label="疗程卡业绩"
					width="120">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="疗程卡提成"
					width="120">
				</el-table-column>
			</el-table-column>
		</el-table><!-- end table -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				date: '',
				radio4: '今日',
				tableData: [{
					id: '全店',
					date: '会员卡',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '银卡',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '还款',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				}]
			}
		}
	}
</script>

<style lang="less" scoped>
.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	.left{
		display: flex;
		align-items: center;
		.select{
			display: flex;
			align-items: center;
			.el-input__inner{
				width: 220px !important;
			}
			.el-radio-group{
				margin: 0 10px;
			}
		}
		span{
			font-size: 16px;
		}
		i{
			font-style: normal;
		}
	}
	.right{
		display: flex;
		align-items: center;
		span{
			margin-left: 30px;
			color: #475669;
		}
	}
	
	.prompt{
		margin-left: 20px;
	}
	.center{
		display: flex;
		align-items: center;
	}
	.el-select{
		margin-right: 10px;
	}
}

.el-table {
	font-size: 14px;
	margin-top: 20px;
}
</style>
